import { useEffect, useState } from 'react';

// Components
import CustomDataTable from '../../components/DataTable';

// Constants
import { APPLY_JOB_COLUMNS } from '../../constants/general';

// Utility Functions
import { getApplyJobDetailsData } from '../../middleware/applyJobs';
import { Actions } from '../../redux/actions';
import { dispatch } from '../../utils/store';

// Utility Packages
import { useSelector } from 'react-redux';

const AppliedJobs = () => {
  // Store
  const { applyJobs, totalRecords, search, pageNumber, size, sortBy, orderBy } = useSelector(
    (state) => state.applyJobs
  );
  const toastRef = useSelector((state) => state.notifications.toastReff);

  // State
  const [applyJobDetails, setApplyJobDetails] = useState([]);

  useEffect(
    () => getApplyJobDetailsData(search, pageNumber, size, sortBy, orderBy, toastRef),
    [search, pageNumber, size, sortBy, orderBy, toastRef]
  );

  useEffect(() => {
    const modifiedRecords = applyJobs?.map((applyJob) => {
      return {
        ...applyJob,
        JobTitle: applyJob?.JobDetails?.JobTitle || '--',
        Position: applyJob?.JobDetails?.Position || '--',
        Salary: `${applyJob.JobDetails.Salary} LPA`,
        ExpectedCTC: `${applyJob.ExpectedCTC} LPA`
      };
    });
    setApplyJobDetails(modifiedRecords);
  }, [applyJobs]);

  useEffect(() => {
    return () => {
      dispatch(Actions.ApplyJobs.ApplyJobsReset);
    };
  }, []);

  const handleSearch = (searchValue) => {
    dispatch(Actions.ApplyJobs.SetSearch, searchValue);
  };
  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.ApplyJobs.SetPageNumber, pageNumber);
    dispatch(Actions.ApplyJobs.SetPageSize, size);
  };
  const handleSort = (field) => {
    let sortOrder = 'ASC';
    if (sortBy === field) sortOrder = orderBy === 'ASC' ? 'DESC' : 'ASC';
    dispatch(Actions.ApplyJobs.SetSortBy, field);
    dispatch(Actions.ApplyJobs.SetOrderBy, sortOrder);
  };

  return (
    <div className="job-table-wrapper">
      <CustomDataTable
        data={applyJobDetails}
        onSearch={handleSearch}
        totalRecords={totalRecords}
        columns={APPLY_JOB_COLUMNS}
        onPagination={handlePagination}
        onSort={handleSort}
      />
    </div>
  );
};

export default AppliedJobs;
