import { useEffect, useState } from 'react';

// Components
import AddEditProjectDetails from '../../components/AddEditProjectDetails';
import CustomDataTable from '../../components/DataTable';

// Constants
import { PROJECT_DETAILS_COLUMNS } from '../../constants/general';

// Utility Functions
import { deleteProject, getProjectDetailsData } from '../../middleware/projectDetails';

// Utility Packages
import { Button } from 'primereact/button';
import { Chip } from 'primereact/chip';
import { ConfirmDialog } from 'primereact/confirmdialog';
import { useSelector } from 'react-redux';
import { Tooltip as ReactTooltip } from 'react-tooltip';
import { Actions } from '../../redux/actions';
import { dispatch } from '../../utils/store';

// images
import deleteIcon from '../../assets/images/grid-delete-icon.svg';
import editIcon from '../../assets/images/grid-edit-icon.svg';

function ProjectDetails() {
  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { projects, totalRecords, search, pageNumber, size } = useSelector(
    (state) => state.projectDetails
  );

  // State
  const [projectDetailsModal, setProjectDetailsModal] = useState(false);
  const [projectsDetails, setProjectDetails] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);
  const [deleteProjectModal, setDeleteProjectModal] = useState(false);

  useEffect(
    () => getProjectDetailsData(search, pageNumber, size, toastReff),
    [search, pageNumber, size]
  );

  const onEditClick = (project) => {
    setSelectedProject(project);
    setProjectDetailsModal(true);
  };

  const onDeleteClick = (project) => {
    setSelectedProject(project);
    setDeleteProjectModal(true);
  };

  useEffect(() => {
    return () => {
      dispatch(Actions.Projects.ProjectsReset);
    };
  }, []);
  
  useEffect(() => {
    const modifiedRecords = projects?.map((project) => {
      return {
        ...project,
        Duration: `${project?.Duration} Y` || '--',
        Technology: project?.Technology && project.Technology?.split(',')?.length && (
          <div className="technology-chip-list flex flex-wrap gap-2">
            {project.Technology?.split(',').map((tech) => (
              <Chip label={tech} className="technology-chip" />
            ))}
          </div>
        ),
        action: (
          <div className="table-actions-wrapper">
            <Button
              data-tooltip-id="edit-button"
              data-tooltip-content="Edit"
              // icon="pi pi-pencil"
              rounded
              text
              severity="secondary"
              aria-label="Edit"
              onClick={() => onEditClick(project)}
              className="grid-action-btn">
              <em>
                <img src={editIcon} alt="edit icon" />
              </em>
            </Button>
            <Button
              data-tooltip-id="delete-button"
              data-tooltip-content="Delete"
              // icon="pi pi-trash"
              rounded
              text
              // severity="danger"
              aria-label="Delete"
              onClick={() => onDeleteClick(project)}
              className="grid-action-btn">
              <em>
                <img src={deleteIcon} alt="delete icon" />
              </em>
            </Button>
            <ReactTooltip id="edit-button" place="top" />
            <ReactTooltip id="delete-button" place="top" />
          </div>
        )
      };
    });
    setProjectDetails(modifiedRecords);
  }, [projects]);

  const handlePagination = (pageNumber, size) => {
    dispatch(Actions.Projects.SetPageNumber, pageNumber);
    dispatch(Actions.Projects.SetPageSize, size);
  };

  const handleSearch = (searchValue) => {
    dispatch(Actions.Projects.SetSearch, searchValue);
  };

  return (
    <>
      <ConfirmDialog
        header="Delete"
        message="Are you sure you want to delete?"
        icon="pi pi-exclamation-triangle"
        visible={deleteProjectModal}
        onHide={() => {
          setSelectedProject(null);
          setDeleteProjectModal(false);
        }}
        accept={() =>
          deleteProject(
            selectedProject?.ID,
            search,
            pageNumber,
            size,
            toastReff,
            setDeleteProjectModal
          )
        }
      />
      <CustomDataTable
        data={projectsDetails}
        onSearch={handleSearch}
        onAdd={() => setProjectDetailsModal(true)}
        totalRecords={totalRecords}
        addButtonTooltip="Add Project"
        columns={PROJECT_DETAILS_COLUMNS}
        onPagination={handlePagination}
      />

      {projectDetailsModal && (
        <AddEditProjectDetails
          show={projectDetailsModal}
          onHide={() => {
            setProjectDetailsModal(false);
            setSelectedProject(null);
          }}
          selectedProject={selectedProject}
        />
      )}
    </>
  );
}

export default ProjectDetails;
