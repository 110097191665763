import { useCallback, useEffect, useState } from 'react';

// Components
import Label from './Label';

// Constants
import { APPLY_JOB_NOTE, LABELS, NOTICE_PERIOD_LABEL } from '../constants/general';
import { ApplyJobValidationSchema } from '../constants/schemas';

// Utility Functions
import {
  AddApplyJobDetails,
  getEducationData,
  getProfileData,
  getResumeData
} from '../middleware/applyJobs';
import { getFormErrorMessage, isFormFieldValid } from '../utils/general';

// Utility Packages
import { useFormik } from 'formik';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from 'primereact/inputnumber';
import { InputText } from 'primereact/inputtext';
import { classNames } from 'primereact/utils';
import { useSelector } from 'react-redux';

const ApplyJob = ({ selectedJobId, visible, onHide }) => {
  //Store
  const toastRef = useSelector((state) => state.notifications.toastReff);
  //State
  const [resumeOptions, setResumeOptions] = useState([]);
  const [qualificationOptions, setQualificationOptions] = useState([]);
  const [jobData, setJobData] = useState({
    firstName: '',
    middleName: '',
    lastName: '',
    email: '',
    mobile: null,
    alternateMobile: null,
    totalExperience: '',
    currentLocation: '',
    highestQualification: '',
    currentCompany: '',
    currentCTC: '',
    expectedCTC: '',
    noticePeriod: '',
    resumeFile: ''
  });

  const formik = useFormik({
    initialValues: jobData,
    validationSchema: ApplyJobValidationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      const education = qualificationOptions.find((edu) => edu.ID === values.highestQualification);
      const resume = resumeOptions.find((edu) => edu.ID === values.resumeFile);

      delete education.label;
      delete education.value;
      delete resume.label;
      delete resume.label;

      const payload = { ...values };
      payload.highestQualification = education;
      payload.resumeFile = resume;
      AddApplyJobDetails(selectedJobId, payload, CloseDialog, toastRef);
    }
  });

  const updatedData = useCallback((data) => {
    setJobData((preState) => ({
      ...preState,
      firstName: data.FirstName || '',
      middleName: data.MiddleName || '',
      lastName: data.LastName || '',
      email: data.Email || '',
      mobile: data.Mobile,
      alternateMobile: data.SecondaryMobile,
      currentLocation: data.Location || ''
    }));
  }, []);

  useEffect(() => {
    if (visible) {
      getProfileData(updatedData);
      getResumeData(setResumeOptions);
      getEducationData(setQualificationOptions);
      formik.setTouched({});
      formik.setErrors({});
    }
  }, [visible, updatedData]);

  const CloseDialog = () => {
    onHide();
    formik.resetForm();
  };
  const renderHeader = () => {
    return <div className="apply-job-header">Apply for this job</div>;
  };

  return (
    <Dialog
      header={renderHeader}
      visible={visible}
      style={{ width: '90vw', maxWidth: '1000px' }}
      onHide={CloseDialog}
      modal
      className="p-fluid apply-job-modal">
      <form onSubmit={formik.handleSubmit} className="profile-info-wrapper">
        <div className="form-row-wrapper">
          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="firstName" text={LABELS.INPUT.FIRSTNAME} isBold isMandatory />
                <InputText
                  id="firstName"
                  name="firstName"
                  value={formik.values.firstName}
                  onChange={formik.handleChange}
                  disabled
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'firstName') })}
                />
              </div>
              {getFormErrorMessage(formik, 'firstName')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="middleName" text={LABELS.INPUT.MIDDLENAME} isBold />
                <InputText
                  id="middleName"
                  name="middleName"
                  value={formik.values.middleName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'middleName') })}
                />
              </div>
              {getFormErrorMessage(formik, 'middleName')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="lastName" text={LABELS.INPUT.LASTNAME} isBold isMandatory />
                <InputText
                  id="lastName"
                  name="lastName"
                  value={formik.values.lastName}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'lastName') })}
                />
              </div>
              {getFormErrorMessage(formik, 'lastName')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="email" text={LABELS.INPUT.EMAIL} isBold isMandatory />
                <InputText
                  id="email"
                  name="email"
                  value={formik.values.email}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  disabled
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'email') })}
                />
              </div>
              {getFormErrorMessage(formik, 'email')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="mobile" text={LABELS.INPUT.PHONE} isBold isMandatory />
                <InputNumber
                  id="mobile"
                  name="mobile"
                  value={formik.values.mobile}
                  onValueChange={(e) => formik.setFieldValue('mobile', e.value)}
                  onBlur={formik.handleBlur}
                  disabled
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'mobile') })}
                  useGrouping={false}
                />
              </div>
              {getFormErrorMessage(formik, 'mobile')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="alternateMobile" text={LABELS.INPUT.ALT_PHONE} isBold />
                <InputNumber
                  id="alternateMobile"
                  name="alternateMobile"
                  value={formik.values.alternateMobile}
                  onValueChange={(e) => formik.setFieldValue('alternateMobile', e.value)}
                  disabled
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'alternateMobile')
                  })}
                  useGrouping={false}
                />
              </div>
              {getFormErrorMessage(formik, 'alternateMobile')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="totalExperience" text={LABELS.INPUT.TOTAL_EXP} isBold isMandatory />
                <InputNumber
                  id="totalExperience"
                  name="totalExperience"
                  placeholder={LABELS.INPUT.TOTAL_EXP_PLACEHOLDER}
                  value={formik.values.totalExperience}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'totalExperience')
                  })}
                  onValueChange={(e) => formik.setFieldValue('totalExperience', e.value)}
                  mode="decimal"
                  minFractionDigits={2}
                  maxFractionDigits={2}
                  min={0}
                  max={100}
                />
              </div>
              {getFormErrorMessage(formik, 'totalExperience')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label
                  htmlFor="currentLocation"
                  text={LABELS.INPUT.CURRENT_LOCATION}
                  isBold
                  isMandatory
                />
                <InputText
                  id="currentLocation"
                  name="currentLocation"
                  value={formik.values.currentLocation}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'currentLocation')
                  })}
                  disabled
                />
              </div>
              {getFormErrorMessage(formik, 'currentLocation')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label
                  htmlFor="highestQualification"
                  text={LABELS.INPUT.HIGH_QUALIFICATION}
                  isBold
                  isMandatory
                />
                <Dropdown
                  id="highestQualification"
                  name="highestQualification"
                  value={formik.values.highestQualification}
                  options={qualificationOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Qualification"
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'highestQualification')
                  })}
                />
              </div>
              {getFormErrorMessage(formik, 'highestQualification')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label
                  htmlFor="currentCompany"
                  text={LABELS.INPUT.CURRENT_COMPANY}
                  isBold
                  isMandatory
                />
                <InputText
                  id="currentCompany"
                  name="currentCompany"
                  value={formik.values.currentCompany}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  className={classNames({
                    'p-invalid': isFormFieldValid(formik, 'currentCompany')
                  })}
                />
              </div>
              {getFormErrorMessage(formik, 'currentCompany')}
            </div>
          </div>
          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="currentCTC" text={LABELS.INPUT.CURRENT_CTC} isBold isMandatory />
                <div className="p-inputgroup flex-1 salary-wrapper">
                  <InputNumber
                    id="currentCTC"
                    name="currentCTC"
                    value={formik.values.currentCTC}
                    onValueChange={(e) => formik.setFieldValue('currentCTC', e.value)}
                    className={classNames({ 'p-invalid': isFormFieldValid(formik, 'currentCTC') })}
                    mode="decimal"
                    min={0}
                    max={100}
                    minFractionDigits={1}
                    maxFractionDigits={1}
                  />
                  <span className="p-inputgroup-addon">LPA</span>
                </div>
              </div>
              {getFormErrorMessage(formik, 'currentCTC')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="expectedCTC" text={LABELS.INPUT.EXPEXTED_CTC} isBold isMandatory />
                <div className="p-inputgroup flex-1 salary-wrapper">
                  <InputNumber
                    id="expectedCTC"
                    name="expectedCTC"
                    value={formik.values.expectedCTC}
                    onValueChange={(e) => formik.setFieldValue('expectedCTC', e.value)}
                    className={classNames({ 'p-invalid': isFormFieldValid(formik, 'expectedCTC') })}
                    mode="decimal"
                    min={0}
                    max={100}
                    minFractionDigits={1}
                    maxFractionDigits={1}
                  />
                  <span className="p-inputgroup-addon">LPA</span>
                </div>
              </div>
              {getFormErrorMessage(formik, 'expectedCTC')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label
                  htmlFor="noticePeriod"
                  text={LABELS.INPUT.NOTICE_PERIOD}
                  isBold
                  isMandatory
                />
                <Dropdown
                  id="noticePeriod"
                  name="noticePeriod"
                  value={formik.values.noticePeriod}
                  options={NOTICE_PERIOD_LABEL}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Notice Period"
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'noticePeriod') })}
                />
              </div>
              {getFormErrorMessage(formik, 'noticePeriod')}
            </div>
          </div>

          <div className="form-col form-col-3">
            <div className="form-group-outer">
              <div className="custom-form-group">
                <Label htmlFor="resumeFile" text={LABELS.INPUT.RESUME} isBold isMandatory />
                <Dropdown
                  id="resumeFile"
                  name="resumeFile"
                  value={formik.values.resumeFile}
                  options={resumeOptions}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  placeholder="Select Resume"
                  className={classNames({ 'p-invalid': isFormFieldValid(formik, 'resumeFile') })}
                />
              </div>
              {getFormErrorMessage(formik, 'resumeFile')}
            </div>
          </div>

          <div className="form-col has-full-width">
            <div className="error-note">
              <strong>Note: </strong>
              <span>{APPLY_JOB_NOTE}</span>
            </div>
          </div>
        </div>
        <div className="submit-box">
          <div>
            <Button
              type="reset"
              variant="contained"
              onClick={CloseDialog}
              color="error"
              className="gray-btn border-btn">
              {LABELS.BUTTON.CANCEL}
            </Button>
          </div>
          <div>
            <Button type="submit" variant="contained" autoFocus>
              {LABELS.BUTTON.SAVE_APPLICATION}
            </Button>
          </div>
        </div>
      </form>
    </Dialog>
  );
};
export default ApplyJob;
