import { MessageType, StatusCode } from '../constants/general';
import { removeSessionData } from './session';

export function showMessage(toast, content, type) {
  if (!toast) return;

  if (!content) return;

  const message =
    typeof content === 'string' ? content.replaceAll('"', '').replaceAll('\\', '') : content;

  switch (type) {
    case MessageType.Info:
      toast.current.show({ severity: 'info', detail: message, life: 3000 });
      break;

    case MessageType.Error:
      toast.current.show({ severity: 'error', detail: message, life: 3000 });
      break;

    case MessageType.Warn:
      toast.current.show({ severity: 'warn', detail: message, life: 3000 });
      break;

    case MessageType.Success:
      toast.current.show({ severity: 'success', detail: message, life: 3000 });
      break;

    default:
      toast.current.show({ severity: 'info', detail: message, life: 3000 });
      break;
  }
}

export function handleError(toast, error) {
  const status = error?.response?.status;

  const message =
    error?.response?.data?.message || error?.response?.data?.data?.message
      ? error?.response?.data?.message?.message ||
        error?.response?.data?.message ||
        error?.response?.data?.data?.message
      : error?.message
        ? JSON.stringify(error?.message)
        : null;

  if (status) {
    if (status === StatusCode.UnAuthorized) {
      if (message) {
        showMessage(toast, message, MessageType.Error);
      } else {
        showMessage(toast, 'Session expired.', MessageType.Error);
      }
      removeSessionData();
    } else if (message) {
      showMessage(toast, message, MessageType.Error);
    } else {
      showMessage(toast, 'Something went wrong.', MessageType.Error);
    }
  } else {
    showMessage(toast, 'Something went wrong.', MessageType.Error);
  }
}

export function handleSuccess(toast, response) {
  if (
    (response?.status === 200 || response?.status === 201 || response?.status === 202) &&
    response?.data?.data?.message
  ) {
    showMessage(toast, JSON.stringify(response.data.data?.message), MessageType.Success);
  } else {
    showMessage(toast, response?.data?.data?.message, MessageType.Error);
  }
}
