import React, { useRef, useState, useCallback } from 'react';

// Utility Package
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { InputText } from 'primereact/inputtext';
import { Dropdown } from 'primereact/dropdown';
import { MultiSelect } from 'primereact/multiselect';
import { Button } from 'primereact/button';
import { Tooltip as ReactTooltip } from 'react-tooltip';
// Utility Function
import { debounce } from '../utils/debounce';
// Constant
import { DEBOUNCE_TIMEOUT, PAGINATION_OPTIONS } from '../constants/general';
// images
import addIcon from '../assets/images/grid-add-icon.svg';
import searchIcon from '../assets/images/grid-search-icon.svg';
import closeIcon from '../assets/images/filter-close-icon.svg';
const CustomDataTable = ({
  data = [],
  columns = [],
  totalRecords = 0,
  searchValue = '',
  dropdown,
  dropdownValue,
  dropdownPlaceHolder = 'Status',
  searchPlaceHolder = 'Search',
  addButtonTooltip = 'Add',
  rowPerPageOption = PAGINATION_OPTIONS,
  onAdd,
  onSort,
  onPagination,
  onSearch,
  onStatusFilter,
  isDropdownMultiSelect = false,
  isdropdownGroup = false,
  disablePaginator = false,
  isHeaderDisable = false
}) => {
  const tableRef = useRef(null);
  const [lazyParams, setLazyParams] = useState({ first: 0, page: 1 });
  const [selectedLimit, setSelectedLimit] = useState(null);
  const [search, setSearch] = useState(searchValue);

  const handlePageChange = (e) => {
    setLazyParams(e);
    setSelectedLimit(e.rows);
    onPagination && onPagination(e.page + 1, e.rows);
  };

  const handleSort = (e) => {
    const fieldName = e.multiSortMeta[0].field;
    const sortColumn = columns.find((col) => col.field === fieldName);
    sortColumn && onSort && onSort(sortColumn.sortBy);
  };
  const debouncedSearch = useCallback(
    debounce((value) => onSearch(value), DEBOUNCE_TIMEOUT),
    [onSearch]
  );

  const handleSearchInput = (e) => {
    setSearch(e.target.value);
    debouncedSearch(e.target.value);
  };

  const tableHeader = !isHeaderDisable && (
    <div className="table-header flex justify-content-between">
      <div className="table-actions-left">
        {onSearch && (
          <div className="searchBox">
            <em className="search-icon">
              <img src={searchIcon} alt="search-icon" />
            </em>
            <InputText
              type="search"
              value={search}
              onInput={handleSearchInput}
              placeholder={searchPlaceHolder}
            />
            {search && (<em
              className="close-icon"
              onClick={() => {
                setSearch('');
                onSearch('');
              }}>
              <img src={closeIcon} alt="close-icon" />
            </em>)}
          </div>
        )}
        {dropdown && (
          <div className="status-select-dropdown">
            {isDropdownMultiSelect ? (
              <MultiSelect
                filter
                value={dropdownValue}
                className="custom-dropdown"
                options={dropdown}
                onChange={(e) => onStatusFilter && onStatusFilter(e.value || '')}
                maxSelectedLabels={2}
                display="chip"
                optionLabel="name"
                optionValue="value"
                placeholder={dropdownPlaceHolder}
                {...(isdropdownGroup && {
                  optionGroupLabel: 'label',
                  optionGroupChildren: 'items'
                })}
              />
            ) : (
              <Dropdown
                value={dropdownValue}
                className="custom-dropdown"
                options={dropdown}
                onChange={(e) => onStatusFilter && onStatusFilter(e.value || null)}
                showClear
                optionLabel="name"
                placeholder={dropdownPlaceHolder}
              />
            )}
          </div>
        )}
      </div>
      {onAdd && (
        <div className="table-actions-right flex">
          <Button
            className="add-user-btn"
            data-tooltip-id="add-button"
            data-tooltip-content={addButtonTooltip}
            // icon="pi pi-plus"
            onClick={onAdd}>
            <em>
              <img src={addIcon} alt="add icon" />
            </em>
          </Button>
          <ReactTooltip id="add-button" place="top" />
        </div>
      )}
    </div>
  );

  return (
    <div className="white-box custom-table-wrapper p-0 overflow-hidden">
      <DataTable
        size="small"
        ref={tableRef}
        value={data}
        header={tableHeader}
        totalRecords={totalRecords}
        paginator={!disablePaginator}
        rows={selectedLimit || 10}
        lazy
        first={lazyParams.first}
        onPage={handlePageChange}
        onSort={handleSort}
        showGridlines
        stripedRows
        responsiveLayout="scroll"
        rowsPerPageOptions={rowPerPageOption}
        paginatorTemplate="CurrentPageReport RowsPerPageDropdown PrevPageLink PageLinks NextPageLink"
        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
        sortMode="multiple">
        <Column
          header="No."
          body={(rowData, options) => lazyParams.first + options.rowIndex + 1}
          style={{ width: '50px', textAlign: 'center' }}
        />
        {columns.map(({ field, header, sortBy, class: columnClass, headerClass, width }) => (
          <Column
            key={field}
            field={field}
            header={header}
            sortable={!!sortBy}
            className={columnClass}
            headerClassName={headerClass}
            style={width ? { width } : {}}
          />
        ))}
      </DataTable>
    </div>
  );
};

export default CustomDataTable;
