import { Accordion, AccordionTab } from 'primereact/accordion';
import { INTERVIEW_PROCESS_STEPS } from '../../constants/general';

function InterviewProcess() {
  return (
    <div className="interview-process-wrapper">
      <Accordion activeIndex={0}>
        {INTERVIEW_PROCESS_STEPS.map(({ number, title, subtitle, content }) => (
          <AccordionTab
            key={number}
            header={
              <div className="accordian-title-wrapper">
                <div className="number-block">
                  <em>{number}</em>
                </div>
                <div className="accordian-title">
                  <p>{title}</p>
                  <span>{subtitle}</span>
                </div>
              </div>
            }>
            <div className="accordian-content">{content}</div>
          </AccordionTab>
        ))}
      </Accordion>
      <div className="important-notes-block">
        <p className="title">Important Notes</p>
        <ul>
          <li>
            Always check your email regularly for the most up-to-date information about your
            interview schedule and updates.
          </li>
          <li>Not all interview rounds may be necessary for every candidate.</li>
          <li>If you need to reschedule, contact HR as soon as possible.</li>
          <li>Prepare thoroughly for each round, as your performance determines progression.</li>
          <li>
            If you face any difficulty during the process, contact us via email at{' '}
            <strong>career@tatvasoft.com</strong> or call us at <strong>+91 960 142 1472</strong>.
          </li>
        </ul>
      </div>
    </div>
  );
}

export default InterviewProcess;
