import { INITIAL_EXPERIENCE, INITIAL_PAGE_NUMBER, INITIAL_SALARY} from '../../constants/general';

export default function job(
  initialState = {
    job: [],
    search: '',
    jobMode: '',
    experience: INITIAL_EXPERIENCE,
    salary: INITIAL_SALARY,
    pageNumber: INITIAL_PAGE_NUMBER,
    size: 9,
    totalRecords: 0,
    totalPages: 0
  },
  action
) {
  switch (action.type) {
    case 'SET_JOB':
      return {
        ...initialState,
        job: action.payload
      };

    case 'SET_JOB_SEARCH':
      return {
        ...initialState,
        search: action.payload
      };
    case 'SET_JOB_Mode':
      return {
        ...initialState,
        jobMode: action.payload
      };

    case 'SET_EXPERIENCE':
      return {
        ...initialState,
        experience: action.payload
      };

    case 'SET_SALARY':
      return {
        ...initialState,
        salary: action.payload
      };

    case 'SET_JOB_PAGE_NUMBER':
      return {
        ...initialState,
        pageNumber: action.payload
      };

    case 'SET_JOB_PAGE_SIZE':
      return {
        ...initialState,
        size: action.payload
      };

    case 'SET_JOB_TOTAL_RECORDS':
      return {
        ...initialState,
        totalRecords: action.payload
      };
    case 'SET_JOB_TOTAL_PAGES':
      return {
        ...initialState,
        totalPages: action.payload
      };

    case 'JOB_RESET':
      return {
        ...initialState,
        job: [],
        pageNumber: INITIAL_PAGE_NUMBER,
        search: '',
        jobMode: '',
        experience: INITIAL_EXPERIENCE,
        salary: INITIAL_SALARY,
        size: 9,
        totalRecords: 0,
        totalPages: 0
      };

    default:
      return initialState;
  }
}
