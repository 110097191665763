import React from 'react';

//Utility Packages
import { Card } from 'primereact/card';
import { useNavigate } from 'react-router-dom';
import { Chip } from 'primereact/chip';
//Constant
import { JobModes } from '../constants/general';

const JobDetailCard = ({ job }) => {
  const navigate = useNavigate();

  return (
    <Card title={job.title} className="job-card" onClick={() => navigate(`/job/${job.id}`)}>
      <p>
        <strong>Position:</strong> {job.position}
      </p>
      <p>
        <strong>Experience:</strong> {job.experience}
      </p>
      <p>
        <strong>Salary:</strong> {job.salary}
      </p>
      <p>
        <strong>Job Mode:</strong>{' '}
        {JobModes.find((mode) => mode.value === job.jobMode)?.label || '--'}
      </p>
      <span>
        <strong>Skills:</strong>
        {job.skills.map((skill , index) => (
          <Chip key={index} label={skill} className="job-skill-badge" />
        ))}
      </span>
    </Card>
  );
};

export default JobDetailCard;
