import { useEffect, useState, useCallback } from 'react';

//Components
import JobDetailCard from '../../components/JobDetailCard';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

//Constants
import { DEBOUNCE_TIMEOUT, JobModes, LABELS } from '../../constants/general';
import { debounce } from '../../utils/debounce';

// Images
import searchIcon from '../../assets/images/grid-search-icon.svg';
import closeIcon from '../../assets/images/filter-close-icon.svg';
import { InputText } from 'primereact/inputtext';
import bannerMobileJpg from '../../assets/images/banner-career-mob.jpg';
import bannerMobileWebp from '../../assets/images/banner-career-mob.webp';
import bannerJpg from '../../assets/images/banner-career.jpg';
import bannerWebp from '../../assets/images/banner-career.webp';

//Utility Functions
import { getJobData } from '../../middleware/job';
import { Actions } from '../../redux/actions';
import { dispatch } from '../../utils/store';

//Utility Packages
import { useSelector } from 'react-redux';
import InfiniteScroll from 'react-infinite-scroller';
import { Slider } from 'primereact/slider';
import { ProgressSpinner } from 'primereact/progressspinner';
import { Checkbox } from 'primereact/checkbox';

const Job = () => {
  // Store
  const { job, search, pageNumber, size, experience, salary, jobMode, totalRecords, totalPages } =
    useSelector((state) => state.job);
  const toastRef = useSelector((state) => state.notifications.toastReff);

  // State
  const [jobData, setJobData] = useState([]);
  const [searchValue, setSearchValue] = useState('');
  const [salaryValue, setSalaryValue] = useState([0, 100]);
  const [experienceValue, setExperienceValue] = useState([0, 100]);
  const [jobModeData, setJobModeData] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    getJobData(search, pageNumber, size, experience, salary,jobMode, toastRef);
    setLoading(false);
  }, [search, pageNumber, size, experience, salary , jobMode]);

  useEffect(() => {
    setLoading(true);
    const modifiedRecords = job?.map((job) => ({
      id: job?.ID,
      title: job?.JobTitle || '--',
      position: job?.Position || '--',
      salary: `${job.Salary} LPA`,
      experience: `${job.Experience} Years`,
      description: job?.JobDescription || '--',
      jobMode:job?.JobMode,
      skills:job?.Skills || [],

    }));
    setJobData((prevData) => [
      ...prevData,
      ...modifiedRecords.filter(
        (record) => !prevData.some((existingRecord) => existingRecord.id === record.id)
      )
    ]);
    setLoading(false);
  }, [job]);

  useEffect(() => {
    return () => {
      dispatch(Actions.Job.JobReset);
    };
  }, []);

  const debouncedHandler = useCallback(
    debounce((type, value) => {
      setJobData([]);
      dispatch(Actions.Job.SetPageNumber, 1);
      switch (type) {
        case 'search':
          dispatch(Actions.Job.SetSearch, value);
          break;
        case 'salary':
          dispatch(Actions.Job.SetSalary, value);
          break;
        case 'experience':
          dispatch(Actions.Job.SetExperience, value);
          break;
        case 'jobMode':
          dispatch(Actions.Job.SetJobMode, value);
          break;
        default:
          break;
      }
    }, DEBOUNCE_TIMEOUT),
    []
  );

  const handleSearch = (val) => {
    setSearchValue(val);
    debouncedHandler('search', val);
  };
  const clearSearch = () => {
    setSearchValue('');
    setJobData([]);
    dispatch(Actions.Job.SetPageNumber, 1);
    dispatch(Actions.Job.SetSearch, '');
  };

  const handleExperienceChange = (val) => {
    if (val[0] < val[1]) {
      setExperienceValue(val);
      debouncedHandler('experience', val);
    }
  };

  const handleSalaryChange = (val) => {
    if (val[0] < val[1]) {
      setSalaryValue(val);
      debouncedHandler('salary', val);
    }
  };

  const fetchMoreData = (PAGE) => {
    if (!loading && pageNumber + 1 <= totalPages) {
      debounce(() => {
        dispatch(Actions.Job.SetPageNumber, pageNumber + 1);
      }, DEBOUNCE_TIMEOUT)();
    }
  };
  const handleJobModeChange = (e) => {
    let _jobMode = [...jobModeData];

    if (e.checked) {
      _jobMode.push(e.value);
    } else {
      _jobMode = _jobMode.filter((mode) => mode !== e.value);
    }
    setJobModeData(_jobMode);
    const jobModeString = _jobMode.join(',');
    debouncedHandler('jobMode', jobModeString);
  };
  return (
    <>
      <Header />
      <section className="page-banner-section inner-page-banner-section">
        <picture className="tatvasoft-inner-banner">
          <source media="(min-width: 768px)" srcSet={`${bannerWebp}, ${bannerJpg}`} />
          <source srcSet={`${bannerMobileWebp}, ${bannerMobileJpg}`} />
          <img src={bannerMobileJpg} alt="Banner Preview" />
        </picture>
        <div className="banner-content-wrapper">
          <div className="container">
            <div className="banner-content">
              <h1>Jobs</h1>
            </div>
          </div>
        </div>
      </section>
      <div className="job-page">
        <div className="job-content">
          <div className="filter-section">
            <div className="filter">
              <h3>Experience</h3>
              <span className="slider-values">{`${experienceValue[0]} year - ${experienceValue[1]} year`}</span>
              <Slider
                value={experienceValue}
                min={0}
                max={100}
                onChange={(e) => handleExperienceChange(e.value)}
                range
              />
              <div className="slider-labels">
                <span>0 Y</span>
                <span>100 Y</span>
              </div>
            </div>
            <div className="filter">
              <h3>Salary</h3>
              <span className="slider-values">{`${salaryValue[0]} LPA - ${salaryValue[1]} LPA`}</span>
              <Slider
                value={salaryValue}
                min={0}
                max={100}
                onChange={(e) => handleSalaryChange(e.value)}
                range
              />
              <div className="slider-labels">
                <span>0 LPA</span>
                <span>100 LPA</span>
              </div>
            </div>
            <div className="filter">
              <h3>Job Mode</h3>
              {JobModes.map((mode, index) => (
                <div key={index} className="field-checkbox">
                  <Checkbox
                    inputId={mode.value}
                    name="jobMode"
                    value={mode.value}
                    onChange={handleJobModeChange}
                    checked={jobModeData.includes(mode.value)}
                  />
                  <label htmlFor={mode.value}>{mode.label}</label>
                </div>
              ))}
            </div>
          </div>
          <div className="job-list-container">
            <div className="searchBox">
              <em className="search-icon">
                <img src={searchIcon} alt="search-icon" />
              </em>
              <InputText
                type="search"
                value={searchValue}
                onChange={(e) => handleSearch(e.target.value)}
                placeholder="Search"
              />
              {searchValue && (
                <em className="close-icon" onClick={clearSearch}>
                  <img src={closeIcon} alt="close-icon" />
                </em>
              )}
            </div>
            <div className="job-list">
              <InfiniteScroll
                threshold={0}
                loader={
                  <div className="loading-container">
                    <ProgressSpinner
                      strokeWidth="8"
                      fill="var(--surface-ground)"
                      animationDuration=".5s"
                    />
                  </div>
                }
                initialLoad={false}
                hasMore={!loading && totalRecords !== 0 && jobData.length < totalRecords}
                loadMore={fetchMoreData}>
                {jobData.map((job, index) => {
                  return <JobDetailCard job={job} key={index} />;
                })}
              </InfiniteScroll>
              {!(!loading && totalRecords !== 0 && jobData.length < totalRecords) &&
                jobData.length === 0 && <div className="no-data-found">{LABELS.JOB_NOT_FOUND}</div>}
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};
export default Job;
