//Constants
import { JOB_API } from '../constants/configs';
import { API_REQUEST } from '../constants/general';
// Utility Functions
import { Actions } from '../redux/actions';
import API from '../utils/api';
import { dispatch } from '../utils/store';
import { handleError, handleSuccess } from '../utils/toast';

export const getJobData = (search, pageNumber, size, experience , salary , jobMode ,toastRef) => {

  const params = {
    ...(!!search ? { searchName: search } : {}),
    ...(!!jobMode ? { jobMode: jobMode } : {}),
    ...(!!experience ? { minExp: experience[0] , maxExp: experience[1] } : {}),
    ...(!!salary ? { minSal: salary[0] , maxSal: salary[1]} : {}),
    ...(!!pageNumber ? { page: pageNumber, size: size } : {})
  };

  API(API_REQUEST.get, `${JOB_API}`, { params })
    .then((res) => {
      handleSuccess(toastRef, res);
      dispatch(Actions.Job.SetJob, res?.data?.data?.data || []);
      dispatch(Actions.Job.SetTotalRecords, res?.data?.data?.count.totalRecords || 0);
      dispatch(Actions.Job.SetTotalPages, res?.data?.data?.count.totalPage || 0);
    })
    .catch((error) => {
      handleError(toastRef, error);
      console.log(error);
    });
};
