import { useEffect } from 'react';

// Components
import BannerInnerPage from '../../components/BannerInnerPage';
import Header from '../../components/Header';
import Footer from '../../components/Footer';

// Constants
import { JOB_APPLY_NOW } from '../../constants/homePage';
import { JobModes, ROUTES } from '../../constants/general';

// Utility Functions
import { getJobDetails } from '../../middleware/jobDetails';

// Utility Packages
import { useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';

function JobDetails() {
  const { id } = useParams();

  // Store
  const { jobPageData } = useSelector((state) => state.jobs);
  const toastRef = useSelector((state) => state.notifications.toastReff);

  useEffect(() => {
    if (id) {
      getJobDetails(id, toastRef);
    }
  }, []);

  return (
    <>
      <div className="main-site-wrapper">
        <Header />
        <main className="main-wrapper job-detail-page">
          <BannerInnerPage title={jobPageData.JobTitle} />
          <div className="career-detail-page-content">
            <div className="container">
              <div className="career-detail-description">
                <p>{jobPageData.JobTitle}</p>
                <p>Experience: {jobPageData.Experience} Y</p>
                <p>Salary: {jobPageData.Salary} LPA</p>
                <p>Job Mode: {JobModes.find((mode) => mode.value === jobPageData.JobMode)?.label || '--'}</p>

              </div>
              <div
                className="career-content-block"
                dangerouslySetInnerHTML={{ __html: jobPageData.JobDescription }}
              />
              <p>
                To apply for this position mail your updated Resume on{' '}
                <a href="mailto:career@tatvasoft.com">career@tatvasoft.com</a>
              </p>
              <div className="btn-wrapper">
                <input type="hidden" />
                <Link to={ROUTES.LOGIN} title={JOB_APPLY_NOW} className="btn yellow-btn">
                  {JOB_APPLY_NOW}
                </Link>
              </div>
            </div>
          </div>
        </main>
        <Footer />
      </div>
    </>
  );
}

export default JobDetails;
