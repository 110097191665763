import equalopportunity from '../assets/images/equal-opportunity.svg';
import leaders from '../assets/images/leaders-support.svg';
import learning from '../assets/images/learning-and-development.svg';
import favorable from '../assets/images/favorable-work-environment.svg';
import transparent from '../assets/images/transparent-evaluation.svg';
import recreational from '../assets/images/recreational-activities.svg';
import festival from '../assets/images/festival-celebration.svg';
import canteen from '../assets/images/canteen-facility.svg';
import weekend from '../assets/images/all-weekends-off.svg';
import healthCare from '../assets/images/health-awareness-sports-events.svg';
import feedback from '../assets/images/feedback-support.svg';
import strongWork from '../assets/images/strong-work-eethics-and-transparency.svg';
import facebook from '../assets/images/facebook.svg';
import twitter from '../assets/images/twitter.svg';
import linkedin from '../assets/images/linkedin.svg';
import dotnet from '../assets/images/microsoft-dotnet.svg';
import fullstack from '../assets/images/full-stack-developer.svg';
import analytic from '../assets/images/analytics.svg';
import onboarding from '../assets/images/selection-onboarding.svg';
import management from '../assets/images/management-interview.svg';
import practicalTest from '../assets/images/practical-test.svg';
import technicalInterview from '../assets/images/technical-interview.svg';

// Header
export const MENU_ITEMS = [
  { title: 'Services', href: '#' },
  { title: 'Technologies', href: '#' },
  { title: 'Industries', href: '#' },
  { title: 'Portfolio', href: '#' },
  { title: 'Insights', href: '#' },
  { title: 'About', href: '#' }
];

// Banner Description
export const BANNER_HEADING = 'Technology evolves, Challenges grow';
export const BANNER_DESCRIPTION = `We are a fast-growing technology engineering company. We make technology roadblocks 
  smoother by offering a multitude of software development services and solutions that 
  help businesses navigate the latest technological disruptions.`;

export const WARNING_MESSAGE = `It has come to our notice that fraudulent job offers are being circulated on behalf of 
  TatvaSoft. If you receive any such offers, please notify us at `;

export const CAREER_DESCRIPTION = `At TatvaSoft, we value our employees and customers as our most important assets. We 
  embrace a clan organizational culture, offering every individual equal opportunities 
  for growth, exposure, and nurturing. We prioritize work-life balance to help our team 
  build a progressive and fulfilling career.`;

// Jobs at Tatvasoft
export const JOBS_TITLE = 'Jobs at TatvaSoft';
export const JOB_READ_MORE = 'Read More';
export const JOB_APPLY_NOW = 'Apply now';
export const MORE = '+More';
export const POSITION = 'Position';
export const EXPERIENCE = 'Experience';
export const JobMode = 'Job Mode';

export const jobs = [
  {
    image: dotnet,
    title: '.NET Developer',
    position: 'SE/SSE/TL',
    experience: '2.5+ years'
  },
  {
    image: fullstack,
    title: 'Full Stack Developer',
    position: 'SE/SSE/TL',
    experience: '3+ years'
  },
  {
    image: analytic,
    title: 'Business Development Executive',
    position: 'BDE/BDM',
    experience: '1+ years'
  }
];

// Benefits at Tatvasoft
export const BENEFITS_WITH_TATVASOFT_TITLE = 'Benefits of working with TatvaSoft:';

export const BENEFITS = [
  {
    image: equalopportunity,
    alt: 'equal-opportunity',
    title: 'Equal Opportunity',
    description:
      'We value and respect every employee, providing ample support for growth and self-development.'
  },
  {
    image: favorable,
    alt: 'favorable-work-environment',
    title: 'Favorable Work Environment',
    description:
      'We are well equipped with necessary amenities, equipment, and conference rooms to facilitate smooth work.'
  },
  {
    image: leaders,
    alt: 'leaders-support',
    title: 'Leaders Support',
    description:
      'Timely guidance, feedback, and constructive criticism to improve and develop skills.'
  },
  {
    image: learning,
    alt: 'learning-and-development',
    title: 'Learning & Development',
    description:
      'Well-defined technical and non-technical training sessions enhance skills and professionalism.'
  },
  {
    image: transparent,
    alt: 'transparent-evaluation',
    title: 'Transparent Evaluation',
    description:
      'Well-defined KRAs/KPIs and 180-degree evaluations ensure fair assessments and salary hikes.'
  },
  {
    image: recreational,
    alt: 'recreational-activities',
    title: 'Recreational Activities',
    description:
      'Gaming zone with pool, table tennis, foosball, and gym equipment for breaks and team bonding.'
  },
  {
    image: festival,
    alt: 'festival-celebration',
    title: 'Festival Celebration',
    description:
      'Festival celebrations, annual functions, and team-building activities to foster creativity and bonding.'
  },
  {
    image: canteen,
    alt: 'canteen-facility',
    title: 'Canteen Facility',
    description:
      'Spacious pantry with meal and snack options, offering a place to enjoy breaks with colleagues.'
  },
  {
    image: weekend,
    alt: 'all-weekends-off',
    title: 'All Weekends Off',
    description:
      'We work weekdays from 9:00 am to 6:30 pm, and weekends are off to promote work-life balance.'
  },
  {
    image: strongWork,
    alt: 'strong-work',
    title: 'Strong Work Ethics and Transparency',
    description: 'Integrity and work ethics are paramount, ensuring a transparent work environment.'
  },
  {
    image: healthCare,
    alt: 'health-awareness-sports-events',
    title: 'Health Awareness and Sports Events',
    description:
      'Yearly sports events and regular health awareness sessions promote employee fitness.'
  },
  {
    image: feedback,
    alt: 'feedback-support',
    title: 'Feedback and Support',
    description:
      'We stay connected with employees and pay attention to everyone’s feedback and queries.'
  }
];

// Recruitment Process
export const RECRUITMENT_TITLE = 'Our Recruitment Process';

export const NOTE_TEXT =
  '*Please note that process for recruitment may vary as per profile and seniority.';

export const RECRUITMENT_STEPS = [
  {
    image: technicalInterview,
    alt: 'technical-interview',
    title: 'Technical Interview',
    description: `Post the usual CV shortlisting and telephonic conversation with recruiter, 
      we schedule interview – which involves- Oral evaluation of your skills, expertise and 
      the way you solve problems. We prefer face to face interviews, but do try to check for 
      the possibility of telephonic/skype as well.`
  },
  {
    image: practicalTest,
    alt: 'practical-test',
    title: 'Practical Test',
    description: `We evaluate your coding, logic and code optimization by assigning a real task. 
      We schedule this round in our office as per your convenience.`,
    blockClass: 'green-block'
  },
  {
    image: management,
    alt: 'management-interview',
    title: 'Management Interview',
    subtitle: 'Interview',
    description: `Quick chat with our management to understand your career vision and cultural fit 
      in organization. We look out for a person with Solid subject matter knowledge and strong work ethics.`,
    blockClass: 'purple-block'
  },
  {
    image: onboarding,
    alt: 'selection-onboarding',
    title: 'Final Selection &',
    subtitle: 'Onboarding',
    description: `Offer Finalization will be done with HR Team. We make our final selection by 
      in-depth consolidation of candidate’s performance in all rounds of interview. Based on mutual 
      decision, joining date will be confirmed, we provide you with best tools on day -1 to facilitate your work.`,
    blockClass: 'orange-block'
  }
];

// FAQs
export const FAQ_TITLE =
  "Want to know more about our recruitment processes? We've compiled a list of our most commonly asked questions to help you through the recruitment journey.";

export const FAQ_DATA = [
  {
    header: 'I am a fresher, can I apply for open positions?',
    content:
      'We fulfill most of our fresher requirements from Campus Recruitment. Yet we welcome talented freshers to join our team. Before you decide to apply for non-technical positions like marketing and business analysis make sure you fulfill the criteria of good communication, self-motivation, and a desire to learn new things.'
  },
  {
    header: 'Can I mail my Resume without applying for any mentioned positions?',
    content:
      'Absolutely, our recruitment team will get in touch with you if we think you are suited for any of the open positions. Also, our candidate database is kept updated to notify you of any future openings.'
  },
  {
    header: 'How long does it take to know the final outcome after the interview?',
    content:
      'Once you are done with all rounds of interview, we try our best to provide the final outcome within a week. If you had to wait longer than that, you are advised to reach the recruiter.'
  },
  {
    header: 'If I am unsuccessful in clearing the interview, can I reapply?',
    content:
      'Yes, we can evaluate your reason for rejection and reschedule your interview. But, you must reapply only once 6 months have passed after your interview date.'
  }
];

export const FAQs_DATA = [
  {
    header: 'How do I create a candidate profile?',
    content:
      'To create a candidate profile:\n- Click on the "Sign Up" or "Register" button on the homepage.\n- Fill in the required details such as your name, email, and password.\n- Verify your email address to activate your account.\n- Log in and complete your profile by adding your resume, skills, and experience.'
  },
  {
    header: 'How do I apply for a job?',
    content:
      'To apply for a job:\n- Log in to your account.\n- Browse through the available job listings on the "Jobs" page.\n- Select a job that interests you.\n- Click the "Apply" button and follow the instructions to submit your application.\n- Ensure your profile is complete to increase your chances of selection.'
  },
  {
    header: 'Can I update my profile after applying for a job?',
    content:
      'Yes, you can update your profile at any time by:\n- Logging into your account and navigating to the "Profile" section.\n- However, the changes may not reflect in applications you have already submitted.'
  },
  {
    header: 'How do I track my job application status?',
    content:
      'To track your application status:\n- Go to the "Applications" section of your dashboard.\n- You can view the status of your applications (e.g., Pending, In Progress, Rejected, Hired).\n- You may also receive email updates about the status of your application.'
  },
  {
    header: 'Can I withdraw a job application?',
    content:
      'Yes, you can withdraw your application:\n- Navigate to the "Applications" section in your dashboard.\n- Find the job application you want to withdraw.\n- Click the "Withdraw" button to cancel your application.'
  },
  {
    header: 'How can I optimize my profile for better job opportunities?',
    content:
      'To optimize your profile:\n- Keep your resume updated with your latest experience and skills.\n- Add a professional photo and write a compelling summary about yourself.\n- List relevant skills, certifications, and achievements.\n- Customize your profile to match the type of jobs you’re applying for.'
  },
  {
    header: 'Do I need to pay to apply for jobs?',
    content: 'No, creating a profile and applying for jobs on our platform is completely free.'
  },
  {
    header: 'How do I reset my password if I forget it?',
    content:
      'To reset your password:\n- Click on the "Forgot Password?" link on the login page.\n- Enter your registered email address.\n- Follow the instructions in the email to reset your password.'
  },
  {
    header: 'Can I apply for multiple jobs simultaneously?',
    content:
      'Yes, you can apply for as many jobs as you want, provided they align with your qualifications and interests.'
  },
  {
    header: 'How do I contact support if I face any issues?',
    content:
      'To contact support:\n- Use the "Contact Us" form available on the website.\n- Email us at [support@example.com](mailto:support@example.com).\n- Call our support hotline at [Support Phone Number].'
  },
  {
    header: 'Can employers view my profile without my permission?',
    content:
      'Employers can only view your profile if:\n- You’ve applied for their job listing.\n- You’ve set your profile visibility to "Public."'
  },
  {
    header: 'Is my data safe on this platform?',
    content:
      'Yes, we prioritize the security of your data:\n- All your information is encrypted and stored securely.\n- Please refer to our Privacy Policy for more details.'
  }
];

export const FAQ_HEADER = 'Recruitment FAQ’s';

// Career Info
export const CAREER_EMAIL = 'career@tatvasoft.com';

export const EMAIL_TITLE = 'Mail us';

export const TEAM_MESSAGE = 'We are always glad to add talented techies to our team.';

export const APPLY_MESSAGE = 'Find the open positions listed below and apply via email at ';

// CTA Block
export const CTA_HEADING = 'Ready to build your custom application solution?';

export const CTA_EMAIL = 'info@tatvasoft.com';

export const CTA_EMAIL_TITLE = 'Mail Us';

export const CTA_PHONE = '+1 469 638 3402';

export const CTA_PHONE_TITLE = 'Call Us';

export const CTA_REQUEST_TITLE = 'Request a Proposal';

export const CTA_BUTTON_TEXT = 'Request a Proposal';

// Footer
export const FOOTER_LINKS = [
  { title: 'Terms of Use', href: '#' },
  { title: 'Privacy', href: '#' },
  { title: 'Articles', href: '#' },
  { title: 'Sitemap', href: '#' }
];

export const SOCIAL_LINKS = [
  { title: 'facebook', href: 'https://www.facebook.com/TatvaSoft', icon: facebook },
  { title: 'twitter', href: 'https://www.twitter.com/TatvaSoft', icon: twitter },
  { title: 'linkedin', href: 'https://www.linkedin.com/TatvaSoft', icon: linkedin }
];
