export const Actions = {
  User: {
    SetIsUserLogged: 'SET_USER_IS_LOGGED_IN',
    SetLoading: 'SET_USER_IS_LOADING',
    UserReset: 'USER_RESET'
  },
  Interviews: {
    SetInterviews: 'SET_INTERVIEWS',
    SetPageNumber: 'SET_INTERVIEWS_PAGE_NUMBER',
    SetPageSize: 'SET_INTERVIEWS_PAGE_SIZE',
    SetTotalRecords: 'SET_INTERVIEWS_TOTAL_RECORDS',
    SetSortBy: 'SET_INTERVIEWS_SORT_BY',
    SetOrderBy: 'SET_INTERVIEWS_ORDER_BY',
    InterviewsReset: 'INTERVIEWS_RESET'
  },
  Notifications: {
    SetNotificationsRef: 'SET_NOTIFICATIONS_TAOST_REF',
    NotificationsReset: 'NOTIFICATIONS_RESET'
  },
  Projects: {
    SetProjects: 'SET_PROJECTS',
    SetSearch: 'SET_PROJECTS_SEARCH',
    SetPageNumber: 'SET_PROJECTS_PAGE_NUMBER',
    SetPageSize: 'SET_PROJECTS_PAGE_SIZE',
    SetTotalRecords: 'SET_PROJECTS_TOTAL_RECORDS',
    SetSortBy: 'SET_PROJECTS_SORT_BY',
    SetOrderBy: 'SET_PROJECTS_ORDER_BY',
    ProjectsReset: 'PROJECTS_RESET'
  },
  Educations: {
    SetEducations: 'SET_EDUCATIONS',
    SetSearch: 'SET_EDUCATIONS_SEARCH',
    SetPageNumber: 'SET_EDUCATIONS_PAGE_NUMBER',
    SetPageSize: 'SET_EDUCATIONS_PAGE_SIZE',
    SetTotalRecords: 'SET_EDUCATIONS_TOTAL_RECORDS',
    SetSortBy: 'SET_EDUCATIONS_SORT_BY',
    SetOrderBy: 'SET_EDUCATIONS_ORDER_BY',
    EducationsReset: 'EDUCATIONS_RESET'
  },
  Employments: {
    SetEmployments: 'SET_EMPLOYMENTS',
    SetSearch: 'SET_EMPLOYMENTS_SEARCH',
    SetPageNumber: 'SET_EMPLOYMENTS_PAGE_NUMBER',
    SetPageSize: 'SET_EMPLOYMENTS_PAGE_SIZE',
    SetTotalRecords: 'SET_EMPLOYMENTS_TOTAL_RECORDS',
    SetSortBy: 'SET_EMPLOYMENTS_SORT_BY',
    SetOrderBy: 'SET_EMPLOYMENTS_ORDER_BY',
    EmploymentsReset: 'EMPLOYMENTS_RESET'
  },
  Resumes: {
    SetResumes: 'SET_RESUMES',
    SetSearch: 'SET_RESUMES_SEARCH',
    SetPageNumber: 'SET_RESUMES_PAGE_NUMBER',
    SetPageSize: 'SET_RESUMES_PAGE_SIZE',
    SetTotalRecords: 'SET_RESUMES_TOTAL_RECORDS',
    SetSortBy: 'SET_RESUMES_SORT_BY',
    SetOrderBy: 'SET_RESUMES_ORDER_BY',
    ResumesReset: 'RESUMES_RESET'
  },
  Jobs: {
    SetJobs: 'SET_JOBS',
    SetSearch: 'SET_JOBS_SEARCH',
    SetJobPageData: 'SET_JOBS_SINGLE_JOB_DATA',
    SetPageNumber: 'SET_JOBS_PAGE_NUMBER',
    SetPageSize: 'SET_JOBS_PAGE_SIZE',
    SetTotalRecords: 'SET_JOBS_TOTAL_RECORDS',
    SetSortBy: 'SET_JOBS_SORT_BY',
    SetOrderBy: 'SET_JOBS_ORDER_BY',
    JobsReset: 'JOBS_RESET'
  },
  ApplyJobs: {
    SetApplyJobs: 'SET_APPLY_JOBS',
    SetSearch: 'SET_APPLY_JOBS_SEARCH',
    SetApplyJobPageData: 'SET_APPLY_JOBS_SINGLE_JOB_DATA',
    SetPageNumber: 'SET_APPLY_JOBS_PAGE_NUMBER',
    SetPageSize: 'SET_APPLY_JOBS_PAGE_SIZE',
    SetTotalRecords: 'SET_APPLY_JOBS_TOTAL_RECORDS',
    SetSortBy: 'SET_APPLY_JOBS_SORT_BY',
    SetOrderBy: 'SET_APPLY_JOBS_ORDER_BY',
    ApplyJobsReset: 'APPLY_JOBS_RESET'
  },
  Job: {
    SetJob: 'SET_JOB',
    SetSearch: 'SET_JOB_SEARCH',
    SetJobMode: 'SET_JOB_Mode',
    SetExperience: 'SET_EXPERIENCE',
    SetSalary: 'SET_SALARY',
    SetPageNumber: 'SET_JOB_PAGE_NUMBER',
    SetPageSize: 'SET_JOB_PAGE_SIZE',
    SetTotalRecords: 'SET_JOB_TOTAL_RECORDS',
    SetTotalPages: 'SET_JOB_TOTAL_PAGES',
    JobReset: 'JOB_RESET'
  },
};
