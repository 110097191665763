import React, { useState } from 'react';

// Constants
import { projectValidationSchema } from '../constants/schemas';
import { getFormErrorMessage, isFormFieldValid, trimStringsInObject } from '../utils/general';
import { LABELS } from '../constants/general';

// Components
import Label from './Label';

// Utility Packages
import { classNames } from 'primereact/utils';
import { Sidebar } from 'primereact/sidebar';
import { Button } from 'primereact/button';
import { useSelector } from 'react-redux';
import { useFormik } from 'formik';
import { Editor } from 'primereact/editor';
import { InputText } from 'primereact/inputtext';
import { InputNumber } from 'primereact/inputnumber';
import CreatableSelect from 'react-select/creatable';

// Utility Functions
import { updateProjectDetails } from '../middleware/projectDetails';

const AddEditProjectDetails = (props) => {
  const { onHide, show, selectedProject } = props;

  // Store
  const toastReff = useSelector((state) => state.notifications.toastReff);
  const { search, pageNumber, size } = useSelector((state) => state.projectDetails);

  // State
  const [technologyInput, setTechnologyInput] = useState('');
  const [technologySuggestion, setTechnologySuggestion] = useState([]);

  const formik = useFormik({
    initialValues: {
      projectTitle: selectedProject?.Title || '',
      technology: selectedProject?.Technology?.length
        ? selectedProject?.Technology?.split(',').map((tech) => ({ label: tech, value: tech }))
        : '',
      duration: selectedProject?.Duration ?? null,
      projectDetails: selectedProject?.Details || ''
    },
    validationSchema: projectValidationSchema,
    onSubmit: (values) => {
      updateProjectDetails(selectedProject, trimStringsInObject(values), onHide, search, pageNumber, size, toastReff);
    }
  });

  const handleTechnologySuggestion = (e) => {
    const options = technologySuggestion.concat(e);
    const newOptions = options.filter(
      (item, index) => options.findIndex((obj) => obj.value === item.value) === index
    );
    setTechnologySuggestion(newOptions);
  };

  return (
    <>
      <Sidebar visible={show} onHide={onHide} position="right" className="sidebar-drawer">
        <div className="form-box-wrapper change-password-form">
          <div className="title-wrapper">
            <p className="card-title">Project</p>
            <button className="p-sidebar-close" onClick={onHide}>
              <span className="p-sidebar-close-icon pi pi-times" />
            </button>
          </div>
          <form onSubmit={formik.handleSubmit} className="p-fluid">
            <div className="form-row-wrapper form-row-wrap">
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="projectTitle"
                      text={LABELS.INPUT.PROJECT_TITLE}
                      isBold
                      isMandatory
                    />
                    <InputText
                      id="projectTitle"
                      name="projectTitle"
                      placeholder={LABELS.INPUT.PROJECT_TITLE}
                      value={formik.values.projectTitle}
                      onChange={formik.handleChange}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'projectTitle')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'projectTitle')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label htmlFor="duration" text={LABELS.INPUT.DURATION} isBold isMandatory />
                    <InputNumber
                      id="duration"
                      name="duration"
                      placeholder={LABELS.INPUT.TOTAL_EXP_PLACEHOLDER}
                      value={formik.values.duration}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'duration')
                      })}
                      onValueChange={(e) => formik.setFieldValue('duration', e.value)}
                      mode="decimal"
                      minFractionDigits={1}
                      maxFractionDigits={1}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'duration')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group autocomplete">
                    <Label htmlFor="technology" text={LABELS.INPUT.TECHNOLOGY} isBold isMandatory />
                    <CreatableSelect
                      isMulti
                      hideSelectedOptions={false}
                      id="technology"
                      name="technology"
                      value={formik.values.technology}
                      onBlur={formik.handleBlur}
                      onChange={(e) => {
                        formik.setFieldValue('technology', e);
                        handleTechnologySuggestion(e);
                      }}
                      options={technologySuggestion}
                      placeholder={LABELS.INPUT.TECHNOLOGY}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'technology')
                      })}
                      classNamePrefix="custom-create-select"
                      inputValue={technologyInput}
                      onKeyDown={(e) => {
                        if (e.key === 'Enter') {
                          e.preventDefault();
                          e.stopPropagation();
                          if (e.target.value) {
                            const newOption = { label: e.target.value, value: e.target.value };
                            formik.setFieldValue('technology', [
                              ...formik.values.technology,
                              newOption
                            ]);
                            handleTechnologySuggestion([newOption]);
                            setTechnologyInput('');
                          }
                        }
                      }}
                      onInputChange={(inputValue) => {
                        setTechnologyInput(inputValue);
                      }}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'technology')}
                </div>
              </div>
              <div className="form-col full-width">
                <div className="form-group-outer">
                  <div className="custom-form-group">
                    <Label
                      htmlFor="projectDetails"
                      text={LABELS.INPUT.PROJECT_DETAILS}
                      isBold
                      isMandatory
                    />
                    <Editor
                      id="projectDetails"
                      name="projectDetails"
                      value={formik.values.projectDetails}
                      onTextChange={(e) => formik.setFieldValue(`projectDetails`, e.htmlValue)}
                      style={{ height: '260px' }}
                      className={classNames({
                        'p-invalid': isFormFieldValid(formik, 'projectDetails')
                      })}
                    />
                  </div>
                  {getFormErrorMessage(formik, 'projectDetails')}
                </div>
              </div>
            </div>
            <div className="form-btn-wrapper">
              <Button
                type="button"
                variant="contained"
                className="ims-blue-btn"
                onClick={formik.handleSubmit}>
                {LABELS.BUTTON.SAVE}
              </Button>
              <Button
                variant="contained"
                onClick={onHide}
                color="error"
                className="gray-btn border-btn">
                {LABELS.BUTTON.CANCEL}
              </Button>
            </div>
          </form>
        </div>
      </Sidebar>
    </>
  );
};

export default AddEditProjectDetails;
